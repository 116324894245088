@import "../../styles.scss";

.page {

}

.positionBlock {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  min-width: 550px;
}


.wrapper {
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-family: Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-variant: tabular-nums;
}

.list {
  margin-right: 40px;
  margin-bottom: 28px;
}

.list__item {
  display: inline-block;
  width: 7.5em;
  height: 7.5em;
  margin: 0 .7em;
  fill: none;
}

.listDashArrayAnim .list__item {
  stroke-dashoffset: 500;
  animation: dasharray 6s infinite ease-in;
}

@keyframes dasharray {
  100% {
    stroke-dashoffset: -500;
  }
}
.info {
  display: flex;
  flex-direction: column;
}
.info__title {
  font-size: 2em;
  font-weight: lighter;
  font-family: "Averta CY";
  margin-bottom: 28px;

}

.info__btn {
  text-decoration: none;
  max-width: max-content;
  padding: 15px 28px;
  background: $primaryColor;
  border-radius: 3px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-self: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.link__btn {
  text-decoration: none;
}

.info__text {
  margin-top: 16px;
  padding: 16px 10%;
}